import {InsightsResources} from '../../../client/strings'
import type {MemexChartConfiguration, MemexChartOperation} from '../../api/charts/contracts/api'
import type {DataSeries, DataSeriesDatum} from '../../api/insights/contracts'
import {isNumber} from '../../helpers/parsing'
import type {ColumnModel} from '../../models/column-model'
import {getYAxisAggregateLabel} from '../../pages/insights/components/insights-configuration-pane/y-axis-selector'
import {useChartSeriesQuery} from '../../queries/use-chart-series-query'
import {useFindColumnByDatabaseId} from '../columns/use-find-column-by-database-id'

export function getAxis(
  xAxisColumnModel?: ColumnModel,
  yAxisColumnModel?: ColumnModel,
  memexChartOperation?: MemexChartOperation,
) {
  const xAxis = {name: xAxisColumnModel?.name || 'Unknown', dataType: 'nvarchar'}
  let yAxis = {
    name: 'Count',
    dataType: 'int',
  }

  if (yAxisColumnModel && memexChartOperation && memexChartOperation !== 'count') {
    yAxis = {
      name: getYAxisAggregateLabel(memexChartOperation, yAxisColumnModel.name),
      dataType: 'int',
    }
  }

  return {xAxis, yAxis}
}

function formatSeriesDatum(
  datum: DataSeriesDatum,
  noValue: string,
  yAxisColumnModel?: ColumnModel,
  memexChartOperation?: MemexChartOperation,
) {
  if (datum.name === '_noValue') {
    return {
      ...datum,
      name: noValue,
    }
  }

  if (!datum.name && memexChartOperation) {
    return {
      ...datum,
      name: getYAxisAggregateLabel(
        memexChartOperation,
        yAxisColumnModel && memexChartOperation !== 'count' ? yAxisColumnModel.name : InsightsResources.items,
      ),
    }
  }

  return datum
}

export function useChartSeries(configuration: MemexChartConfiguration) {
  const {findColumnByDatabaseId} = useFindColumnByDatabaseId()

  const xAxisColumn = configuration.xAxis.dataSource.column
  const yAxisOperationColumn = configuration.yAxis.aggregate?.columns?.[0]
  const yAxisOperation = configuration.yAxis.aggregate?.operation

  const xAxisColumnModel = isNumber(xAxisColumn) ? findColumnByDatabaseId(xAxisColumn) : undefined
  const yAxisColumnModel = isNumber(yAxisOperationColumn) ? findColumnByDatabaseId(yAxisOperationColumn) : undefined

  const axis = getAxis(xAxisColumnModel, yAxisColumnModel, configuration.yAxis.aggregate.operation)

  const {filter, xAxis} = configuration
  const chartQuery = useChartSeriesQuery({filter, xAxis})
  const isLoading = !chartQuery.data

  let series: DataSeries = []
  let xCoordinates: Array<string> = []

  if (chartQuery.data && xAxisColumnModel?.name) {
    const noValue = InsightsResources.negateStr(xAxisColumnModel.name)
    series = chartQuery.data.dataSeries.map(d => formatSeriesDatum(d, noValue, yAxisColumnModel, yAxisOperation))
    xCoordinates = chartQuery.data.xAxis.values.map(v => (v === '_noValue' ? noValue : v))
  }

  // For feature parity with non-MWL projects, do not show the matching document count unless a filter is applied
  const filterCount = filter.trim() ? chartQuery.data?.totalCount : undefined

  return {
    series,
    axis,
    xCoordinates,
    isLoading,
    filterCount,
  }
}
