import {ChartCard} from '@github-ui/chart-card'
import {GraphIcon} from '@primer/octicons-react'
import {Box, Heading} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {memo} from 'react'

import type {MemexChartConfiguration} from '../../../api/charts/contracts/api'
import {highChartTypes, isStacked, shouldApplyPointPlacement} from '../../../api/charts/contracts/api'
import {useEnabledFeatures} from '../../../hooks/use-enabled-features'
import type {MemexItemModel} from '../../../models/memex-item-model'
import {useHistoricalChartSeriesChartCard} from '../../../state-providers/charts/use-historical-chart-series'
import {useLeanHistoricalChartSeriesChartCard} from '../../../state-providers/charts/use-lean-historical-chart-series'
import {getLegendLabel} from '../highcharts-theme'

export const LeanHistoricalInsightsChart = memo<{
  configuration: MemexChartConfiguration
  endDate?: string
  filteredItems: Array<MemexItemModel> | null
  filterValue: string
  startDate?: string
}>(function LeanHistoricalInsightsChart({configuration, endDate, filteredItems, startDate}) {
  const {memex_table_without_limits} = useEnabledFeatures()
  const {series, xCoordinates} = memex_table_without_limits
    ? // eslint-disable-next-line react-compiler/react-compiler
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useHistoricalChartSeriesChartCard({configuration, startDate, endDate})
    : // eslint-disable-next-line react-compiler/react-compiler
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLeanHistoricalChartSeriesChartCard({
        configuration,
        filteredItems,
        startDate,
        endDate,
      })

  const chartType = configuration.type
  return series.length ? (
    // eslint-disable-next-line @github-ui/dotcom-primer/require-children
    <ChartCard padding="spacious" size="xl">
      <ChartCard.Chart
        series={series as Array<Highcharts.SeriesOptionsType>}
        xAxisTitle="Date"
        xAxisOptions={{
          title: {
            text: null,
          },
          categories: xCoordinates,
          tickAmount: 28,
        }}
        yAxisTitle="items"
        yAxisOptions={{
          title: {
            text: null,
          },
          maxPadding: 0,
          allowDecimals: false,
        }}
        plotOptions={{
          series: {
            stacking: isStacked(chartType) ? 'normal' : undefined,
            marker: {
              enabled: false,
            },
            pointPlacement: shouldApplyPointPlacement(configuration.type) ? 'on' : undefined,
          },
        }}
        overrideOptionsNotRecommended={{
          legend: {
            reversed: true,
            align: 'right',
            layout: 'horizontal',
            verticalAlign: 'bottom',
            useHTML: true,
            symbolHeight: 0,
            symbolWidth: 0,
            labelFormatter() {
              return getLegendLabel(this.options as Highcharts.PointOptionsObject, this.name)
            },
          },
          tooltip: {
            valuePrefix: '&nbsp;',
          },
        }}
        type={highChartTypes[chartType]}
      />
    </ChartCard>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 1,
        borderWidth: 1,
        borderRadius: 2,
        borderStyle: 'solid',
        borderColor: 'border.default',
        minHeight: '526px', // match height of charts with data
        padding: 4,
      }}
    >
      <Octicon icon={GraphIcon} size="medium" sx={{fill: 'fg.muted'}} />
      <Heading as="h3" sx={{fontSize: 3}}>
        No data available
      </Heading>
      <Box as="p" sx={{color: 'fg.muted'}}>
        No results were returned.
      </Box>
    </Box>
  )
})

try{ LeanHistoricalInsightsChart.displayName ||= 'LeanHistoricalInsightsChart' } catch {}