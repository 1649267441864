import {useQuery} from '@tanstack/react-query'

import {apiGetChart} from '../api/insights/api-get-chart'
import type {GetChartRequest} from '../api/insights/contracts'

export const chartSeriesQueryKey = 'chart-series-data'

export function useChartSeriesQuery(request: GetChartRequest) {
  const query = useQuery({
    queryKey: [chartSeriesQueryKey, request],
    queryFn: async () => {
      return apiGetChart(request)
    },
    staleTime: Infinity,
  })

  return query
}
